$primary: #F0F5FC;
$secondary: #3B47E4;

$black: #000;
$red: #fd0000;
$gray: #5A5A5A;
$white: #fff;
$blue: #3B47E4;
$blue-black: #2F3BD1;

.color-white {
  color: $white
}

.color-red {
  color: $red
}
