@font-face {
  font-family: 'AvenirLTProLight';
  src: url('~assets/fonts/avenir-lt-pro-cufonfonts/AvenirLTProLight.otf') format('truetype'),
  url('~assets/fonts/avenir-lt-pro-cufonfonts/AvenirLTProLight.otf') format('truetype');
  font-weight: normal;
  font-style: normal;
}

.font-avenirRegular {
  font-family: 'AvenirLTProLight', sans-serif;
}

@font-face {
  font-family: 'AvenirLtProBlack';
  src: url('~assets/fonts/avenir-lt-pro-cufonfonts/AvenirLtProBlack.otf') format('truetype');
  font-weight: bold;
  font-style: normal;
}

.font-avenirBold {
  font-family: 'AvenirLtProBlack', sans-serif;
}


@font-face {
  font-family: 'AvenirLTProMedium';
  src: url('~assets/fonts/avenir-lt-pro-cufonfonts/AvenirLTProMedium.otf') format('truetype');
  font-weight: normal;
  font-style: normal;
}

.font-avenirMedium {
  font-family: 'AvenirLTProMedium', sans-serif;
}
