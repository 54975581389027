/* You can add global styles to this file, and also import other style files */

@import '~bootstrap/dist/css/bootstrap.min.css';



@import "~assets/fonts/index";
@import '~assets/css/hover.css';
@import "~assets/scss/breakpoints";
@import "~assets/scss/variables";


body{
  font-family: 'AvenirLTProLight', sans-serif;
}
